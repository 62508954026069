import { ReactElement } from 'react';
import styles from './Signup.module.scss';

export default function Signup(): ReactElement {
	return (
		<main id={styles.main}>
			<img className={styles.hero} src="/img/logo.svg" alt=""></img>
			<h1 className={styles.title}>
				Please check your email for a link to use Perla
			</h1>
			<p className={styles.text}>
				Need help?{' '}
				<a className={styles.link} href="mailto:support@goperla.com">
					Contact us
				</a>{' '}
				to assist you
			</p>
		</main>
	);
}
