import axios from 'axios';
import { config } from '../../config';

export default class AffiliateAPI {
	static async checkAffiliateId(affiliateId: string) {
		await axios.head(`${config().api}/affiliates/${affiliateId}`, {
			headers: { Authorization: `Bearer ${affiliateId}` },
		});
	}
}
