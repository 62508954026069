import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type SearchCriteria = SearchState;

export interface SearchState {
	hcpAdvisorType?: string;
	hcpAdvisorLastName?: string;
	hcpAdvisorCompanyName?: string;
	serviceId: string;
	zipCode: string;
	viewedAdvisorIds: string[];
	sentLeadsAdvisorIds: string[];
	justSentLead: boolean;
}

const initialState: SearchState = {
	hcpAdvisorType: '',
	hcpAdvisorLastName: '',
	hcpAdvisorCompanyName: '',
	serviceId: '',
	zipCode: '',
	viewedAdvisorIds: [],
	sentLeadsAdvisorIds: [],
	justSentLead: false,
};

export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchCriteria: (state,	action: PayloadAction<Partial<SearchCriteria>>) => {
			let foo = { ...state, ...action.payload };
			return foo;
		},
		resetSearch: state => initialState,
		addViewedAdvisorId: (state, action: PayloadAction<string>) => {
			state.viewedAdvisorIds.push(action.payload);
			state.justSentLead = false;
		},
		addSentLeadAdvisorId: (state, action: PayloadAction<string>) => {
			state.sentLeadsAdvisorIds.push(action.payload);
			state.justSentLead = true;
		},
	},
});

export const {
	setSearchCriteria,
	resetSearch,
	addViewedAdvisorId,
	addSentLeadAdvisorId,
} = searchSlice.actions;

export const selectSearchCriteria = (state: RootState) => state.search;

export default searchSlice.reducer;
