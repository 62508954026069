import React, {
	lazy,
	ReactElement,
	Suspense,
	useEffect,
	useState,
} from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import { useAppDispatch } from './app/hooks';
import { RootState } from './app/store';
import Nav from './common/Nav';
import RouteLoader from './components/routeLoader/RouteLoader';
import AffiliateAPI from './features/affiliates/affiliateAPI';
import { setAffiliateId } from './features/affiliates/affiliateSlice';
import Signup from './routes/signup/Signup';

const routes = [
	{
		path: '/',
		component: lazy(() => import('./routes/home/Home')),
		exact: true,
	},
	{
		path: '/services',
		component: lazy(() => import('./routes/services/Services')),
	},
	{
		path: '/location',
		component: lazy(() => import('./routes/location/Location')),
	},
	{
		path: '/personal',
		component: lazy(() => import('./routes/personal/Personal')),
	},
	{
		path: '/search',
		component: lazy(() => import('./routes/search/Search')),
	},
	{
		path: '/search-again',
		component: lazy(() => import('./routes/searchAgain/SearchAgain')),
	},
	{
		path: '/advisor/:advisorId/contact',
		component: lazy(() => import('./routes/contact/Contact')),
	},
	{
		path: '/advisor/:advisorId',
		component: lazy(() => import('./routes/advisor/AdvisorRoute')),
	},
];

interface AppProps {
	affiliateId: string;
}

function mapStateToProps(state: RootState): AppProps {
	// eslint-disable-next-line no-restricted-globals
	const search = location.search;
	const queryParamAffiliateId = new URLSearchParams(search).get(
		'affiliateId',
	);
	return {
		affiliateId: queryParamAffiliateId ?? state.affiliate.affiliateId,
	};
}

function App({ affiliateId }: AppProps): ReactElement {
	const dispatch = useAppDispatch();
	const [affiliateOK, setAffiliateOK] = useState(null as boolean | null);

	useEffect(() => {
		if (!affiliateId) {
			setAffiliateOK(false);
		}

		AffiliateAPI.checkAffiliateId(affiliateId)
			.then(() => {
				dispatch(setAffiliateId(affiliateId));
				setAffiliateOK(true);
			})
			.catch(() => {
				setAffiliateOK(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="app">
			<Router>
				<Nav />
				<Suspense fallback={<RouteLoader />}>
					{affiliateOK === null && <RouteLoader />}
					{affiliateOK === false && <Signup />}
					{affiliateOK === true && (
						<Switch>
							{routes.map((route, index) => (
								<Route
									key={index}
									exact={route.exact ?? false}
									path={route.path}
									component={route.component}
								/>
							))}
						</Switch>
					)}
				</Suspense>
			</Router>
		</div>
	);
}

export default connect(mapStateToProps)(App);
