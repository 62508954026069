import {
	faBars,
	faEnvelope,
	faPhoneVolume,
	faLink
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Nav.module.scss';

interface Props {
	children?: ReactElement;
}

export default function Nav({ children }: Props) {
	const [showLeftBar, setShowLeftBar] = useState(false);

	return (
		<React.Fragment>
			<nav id={styles.topNav}>
				<span
					id={styles.menu}
					onClick={() => setShowLeftBar(!showLeftBar)}
				>
					<FontAwesomeIcon icon={faBars} />
				</span>
				<span id={styles.title}>
					<Link to="/">
						<img src="/logo.svg" alt="" />
					</Link>
				</span>
			</nav>
			<nav id={styles.leftBar} className={showLeftBar ? styles.show : ''}>
				<div id={styles.logo}>
					<Link to="/">
						<img src="/logo.svg" alt="" />
					</Link>
				</div>
				{children}
				<div id={styles.help}>
					<p id={styles.helpText}>Get Help</p>
					<p id={styles.helpTime}>Mon-Fri, 9AM to 5PM EST</p>
					<p>
						<FontAwesomeIcon
							icon={faPhoneVolume}
							className={styles.icon}
						/>{' '}
						<a href="tel:202-800-5858">202-800-5858</a>
					</p>
					<p>
						<FontAwesomeIcon
							icon={faEnvelope}
							className={styles.icon}
						/>{' '}
						<a href="mailto:support@goperla.com">
							support@goperla.com
						</a>
					</p>
					<p>
						<FontAwesomeIcon
							icon={faLink}
							className={styles.icon}
						/>{' '}
						<a href="http://goperla.com/">
							www.goperla.com
						</a>
					</p>
				</div>
			</nav>
			{showLeftBar && (
				<span
					id={styles.closeMenu}
					onClick={() => setShowLeftBar(false)}
				>
					<FontAwesomeIcon icon={faBars} />
				</span>
			)}
			{showLeftBar && <div
				onClick={() => setShowLeftBar(false)}
				id={styles.overlay} />}
		</React.Fragment>
	);
}
